import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const Header = () => {
  const { pathname } = useLocation();
  const [menuOpen, setIsMenuOpen] = useState(false);

  const handleclick = () => {
    setIsMenuOpen(!menuOpen);
  };

  const closemenu = () => {
    setIsMenuOpen(false);
  };

  const mobref = useRef();

  useEffect(() => {
    const closemenu = (e) => {
      if (
        mobref.current &&
        !mobref.current.contains(e.target) &&
        !e.target.closest(".menubarbtn")
      ) {
        setIsMenuOpen(false);
      }
    };
    document.body.addEventListener("click", closemenu);
    return () => document.body.removeEventListener("click", closemenu);
  });

  return (
    <div className=" 2xl:container w-full flex justify-center  bg-F1F5FF sticky top-0 z-50 items-center">
      <div className="p-4 md:px-20 w-full flex items-center h-[88px] justify-between bg-F1F5FF">
        <div className="w-[6/12] md:w-[90%] justify-between md:justify-start flex gap-20">
          <Link to={"/"} className="flex items-center justify-left">
            <img
              className="h-[32px] sm:h-[40px] md:h-[48px] w-auto min-w-[150px]"
              src={process.env.PUBLIC_URL + "/images/sslogo.svg"}
              alt="spectrum_logo"
            />
          </Link>
          {/* <Link to={"/"} className="flex items-center block md:hidden">
            <img
              className="h-auto w-auto"
              src={process.env.PUBLIC_URL + "/images/spectrumlogo.svg"}
              alt="spectrum_logo"
            />
          </Link> */}
          <div className="md:flex hidden items-center gap-1 text-14 md:text-16 font-bold text-3E3C3F ml-auto">
            <Link
              to={"/help&support"}
              className={`px-[10px] py-[8px] hover:text-[#FC7B27] text-14 lg:text-16 ${
                pathname === "/help&support" ? "text-[#FC7B27]" : "text-3E3C3F"
              }`}
            >
              <h1>Help & Support</h1>
            </Link>
          </div>
        </div>
        <HashLink
          smooth
          to={"/#contactus"}
          className="px-4 py-[14px] bg-1D43B6 text-white text-14 lg:text-16 font-bold rounded-[16px] hidden md:block text-14 text-nowrap"
        >
          <p>Contact Us</p>
        </HashLink>
      </div>

      {/* MARK: for Mobile view menubar */}

      <div className="flex md:hidden absolute right-0 z-300 menubarbtn">
        <button
          className="relative flex flex-col items-center justify-center w-12 h-12 rounded focus:outline-none"
          onClick={handleclick}
        >
          <span
            className={`block w-6 h-0.3 border-black border-[1px] opacity-75 border-black transform transition duration-300 ease-in-out ${
              menuOpen ? "rotate-45 translate-y-1" : "-translate-y-1.5"
            }`}
          ></span>
          <span
            className={`block w-6 h-0.3 border-black border-[1px] transform transition duration-300 ease-in-out ${
              menuOpen ? "opacity-0" : "opacity-75"
            }`}
          ></span>
          <span
            className={`block w-6 h-0.3 border-black border-[1px] opacity-75 transform transition duration-300 ease-in-out ${
              menuOpen ? "-rotate-45 -translate-y-1.2" : "translate-y-1.5"
            }`}
          ></span>
        </button>
      </div>
      {menuOpen ? (
        <div
          ref={mobref}
          className="flex flex-col absolute text-left gap-1 text-14 md:text-16 font-bold text-white w-full flex-wrap top-[87px] bg-[#060D24] px-10 py-10"
        >
          <Link
            to={"/ourstory"}
            onClick={closemenu}
            className={`px-[10px] py-[8px] hover:text-[#FC7B27] ${
              pathname === "/ourstory" ? "text-[#FC7B27]" : "text-white"
            }`}
          >
            <h1>Our Story</h1>
          </Link>
          <Link
            to={"/events"}
            onClick={closemenu}
            className={`px-[10px] py-[8px] hover:text-[#FC7B27] ${
              pathname === "/events" ? "text-[#FC7B27]" : "text-white"
            }`}
          >
            <h1>Events</h1>
          </Link>
          <Link
            to={"/resources"}
            onClick={closemenu}
            className={`px-[10px] py-[8px] hover:text-[#FC7B27] ${
              pathname === "/resources" ? "text-[#FC7B27]" : "text-white"
            }`}
          >
            <h1>Resources</h1>
          </Link>
          <Link
            to={"/communitystories"}
            onClick={closemenu}
            className={`px-[10px] py-[8px] hover:text-[#FC7B27] ${
              pathname === "/communitystories" ? "text-[#FC7B27]" : "text-white"
            }`}
          >
            <h1>Community Stories</h1>
          </Link>
          <Link
            to={"/news&updates"}
            onClick={closemenu}
            className={`px-[10px] py-[8px] hover:text-[#FC7B27] ${
              pathname === "/news&updates" ? "text-[#FC7B27]" : "text-white"
            }`}
          >
            <h1>News & Updates</h1>
          </Link>
          <Link
            to={"/successstories"}
            onClick={closemenu}
            className={`px-[10px] py-[8px] hover:text-[#FC7B27] ${
              pathname === "/successstories" ? "text-[#FC7B27]" : "text-white"
            }`}
          >
            <h1>Success Stories</h1>
          </Link>
          <Link
            to={"/blog"}
            onClick={closemenu}
            className={`px-[10px] py-[8px] hover:text-[#FC7B27] ${
              pathname === "/blog" ? "text-[#FC7B27]" : "text-white"
            }`}
          >
            <h1>Blog</h1>
          </Link>
          <Link
            to={"/partnerships"}
            onClick={closemenu}
            className={`px-[10px] py-[8px] hover:text-[#FC7B27] ${
              pathname === "/partnerships" ? "text-[#FC7B27]" : "text-white"
            }`}
          >
            <h1>Partnerships</h1>
          </Link>
          <Link
            to={"/donate"}
            onClick={closemenu}
            className={`px-[10px] py-[8px] hover:text-[#FC7B27] ${
              pathname === "/donate" ? "text-[#FC7B27]" : "text-white"
            }`}
          >
            <h1>Donate/Support Us</h1>
          </Link>
          <Link
            to={"/help&support"}
            onClick={closemenu}
            className={`px-[10px] py-[8px] hover:text-[#FC7B27] ${
              pathname === "/help&support" ? "text-[#FC7B27]" : "text-white"
            }`}
          >
            <h1>Help & Support</h1>
          </Link>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Header;
