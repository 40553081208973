import React, { useState } from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  const [mail, setMail] = useState({ email: "" });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setMail({ [name]: value });
  };

  const date = new Date();

  let year = date.getFullYear();

  return (
    <div className="2xl:container flex flex-col w-full justify-center items-center">
      <div className="flex flex-col w-full p-4 md:px-20 md:py-10 gap-16">
        <div className=" flex flex-col md:flex-row w-full justify-between gap-10 md:gap-0 ">
          <div className="flex flex-col gap-10">
            <div>
              <img
                className="h-[32px] sm:h-[40px] md:h-[48px] w-auto"
                src={process.env.PUBLIC_URL + "/images/sslogo.svg"}
                alt="spectrumlogo"
              />
            </div>
            <div className="flex flex-col md:flex-row text-left gap-8">
              <div className="flex flex-col">
                <p className="text-[#43414D]">E-mail</p>
                <p className="text-[#060D24] font-semibold">
                  admin@socialspectrum.org
                </p>
              </div>
              {/* <div className="flex flex-col">
                <p className="text-[#43414D]">Phone Number</p>
                <p className="text-[#060D24] font-semibold">
                  +1 (201) 895-3801
                </p>
              </div> */}
            </div>
          </div>
          <div className="flex flex-col w-full md:w-3/12 gap-10">
            <h1 className="text-[#060D24] font-bold text-18 text-left">
              To receive updates from the Social Spectrum team, leave your email
              here.
            </h1>
            <div className="flex border-b-[1px] border-[#C1C7DB] justify-between">
              <form
                action="/socialspectrum/api/v1/contact"
                method="post"
                className="input_details w-full"
              >
                <div className="md:grid gap-4">
                  <div className="w-full flex flex-col gap-3 md:gap-5 text-E1E7FA hidden">
                    <label className="text-E1E7FA text-left">First Name</label>
                    <input
                      type="text"
                      name="first_name"
                      value={"Insterested user"}
                      placeholder="First Name"
                      className="inputfield"
                      // required
                    />
                  </div>
                  <div className="w-full flex flex-col gap-3 md:gap-5 text-E1E7FA mt-4 md:mt-0 hidden">
                    <label className="text-E1E7FA text-left">Last Name</label>
                    <input
                      type="text"
                      name="last_name"
                      placeholder="Last Name"
                      className="inputfield"
                    />
                  </div>
                  {/* <div className="w-full flex flex-col gap-3 md:gap-5 mt-4 md:mt-0 text-E1E7FA md:mt-6 hidden">
                    <label className="text-E1E7FA text-left">E-mail</label>
                    <input
                      type="email"
                      name="email"
                      placeholder="E-mail"
                      className="inputfield"
                      required
                    />
                  </div> */}
                  <div className="flex justify-between w-full">
                    <input
                      type="email"
                      placeholder="Enter your email"
                      required
                      name="email"
                      className="outline-none py-2 w-full "
                    />
                    <button type="submit">
                      <img
                        src={process.env.PUBLIC_URL + "/images/sendarrow.png"}
                        alt="submit"
                      />
                    </button>
                  </div>
                  <div className="w-full flex flex-col gap-3 md:gap-5 mt-4 md:mt-0 text-E1E7FA md:mt-6 hidden">
                    <label className="text-E1E7FA text-left">
                      Phone Number
                    </label>
                    <input
                      type="number"
                      name="phone"
                      placeholder="Phone Number"
                      className="inputfield"
                      // required
                    />
                  </div>
                  <div className="w-full flex flex-col gap-3 md:gap-5 mt-4 md:mt-0 col-span-2 text-E1E7FA md:mt-6 hidden">
                    <label className="text-E1E7FA text-left">Message</label>
                    <textarea
                      type="text"
                      name="message"
                      value={
                        "The user is interested to receive updates from Social Spectrum"
                      }
                      placeholder="Write your message"
                      className="inputfield min-h-[134px]"
                      // required
                    ></textarea>
                  </div>
                  <div className="hidden" id="success">
                    <h3 className="">Thank you for contacting us!</h3>
                  </div>
                </div>
                {/* <div className="w-full mt-8 flex justify-left ">
                  <input
                    type="submit"
                    value="Send Message"
                    className="bg-[#1D43B6] px-6 py-4 rounded-[12px] text-white font-bold cursor-pointer"
                  />
                </div> */}
              </form>
              {/* <input
                type="email"
                value={mail.mail}
                placeholder="Enter your email"
                required
                name="email"
                className="outline-none py-2"
                onChange={handleChange}
              />
              <button>
                <img
                  src={process.env.PUBLIC_URL + "/images/sendarrow.png"}
                  alt="submit"
                />
              </button> */}
            </div>
          </div>
        </div>
        <hr className="border-[1px] border-[#C1C7DB]"></hr>
        <div className="text-14 md:text-16 flex flex-col-reverse md:flex-row justify-between items-center gap-10 md:gap-0">
          <div>
            {`Copyright ©.${year}`}
            <Link to={"privacy"} className="text-[#1D43B6] font-semibold">
              {" "}
              Privacy Policy
            </Link>{" "}
            and{" "}
            <Link
              to={"terms_and_conditions"}
              className="text-[#1D43B6] font-semibold"
            >
              Terms & Conditions
            </Link>
          </div>
          <div className="flex gap-3">
            <Link
              to={"/ourstory"}
              className="px-4 py-2 rounded-[40px] border-[1px] border-[#C1C7DB]"
            >
              Our Story
            </Link>
            <Link
              to={"/events"}
              className="px-4 py-2 rounded-[40px] border-[1px] border-[#C1C7DB]"
            >
              Events
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
